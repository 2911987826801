@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Hachi+Maru+Pop&family=Nunito:wght@200;300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200;300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300&display=swap);
@font-face {
  font-family: 'Chamonix';
  src: local('Rajdhani'), url(/static/media/CHAMONIX.510181ac.otf) format('truetype');
}

:root {
  --primary-white: #fff;
  --primary-orange: #f25c54;
  --primary-dark: rgb(52, 58, 64);
  --primary-grey: #ccd1d9;
  --primary-dark-grey: #666666;
  --primary-red: #ef4035;
  --primary-green: green;
  --primary-hover-red: #f33501;
  --primary-light-grey: #f0f2f5;
  --primary-black: #000000;
  --primary-blue: #2727e6;
}

body {
  font-family: 'Roboto Condensed', sans-serif !important;
}

h1 {
  font-size: 2rem !important;
}

/* NAVBAR COMPONENT */

/* .logo {
  width: auto;
  border-radius: 50%;
  height: 3.5rem;
} */

nav {
  z-index: 2;
}

.navbar {
  height: 4rem;
}

.navbar-brand {
  max-width: 17.25rem;
  padding: 0 !important;
  height: 4rem !important;
}

.navbar-brand h2 {
  font-family: 'Hachi Maru Pop', cursive;
  font-size: 2.5rem;
  color: var(--primary-blue);
}

@media (max-width: 768px) {
  .navbar-brand {
    width: 7rem;
  }
}

/* MENU LINKS  */

nav a.nav-link {
  /* font-weight: 500; */
  font-size: 0.8rem;
  letter-spacing: 0.0375rem;
  cursor: pointer;
}

nav ul li {
  text-transform: uppercase;
}

.navbar-nav > li > a {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  color: var(--primary-dark) !important;
  border-top: 0.1875rem solid var(--primary-dark);
}

.navbar-nav > li > a.active,
.navbar-nav > li > a:focus {
  border-top: 0.1875rem solid var(--primary-orange);
}

.navbar-nav > li > a:hover {
  border-top: 0.1875rem solid var(--primary-orange);
}

.navbar-toggler {
  color: var(--primary-dark);
  background: var(--primary-dark);
}

/* HEADER */

.header-wraper {
  position: relative;
  /* background: url(background.jpg) no-repeat; */
  opacity: 0.8;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;
  /* z-index: 1; */
  overflow: hidden;
}

.parallax {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.main-info {
  display: flex;
  position: absolute;
  /* z-index: 1; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.main-info h1 {
  color: var(--primary-white);
  font-size: 4rem !important;
  font-family: 'Chamonix';
  text-transform: uppercase;
  z-index: 3;
}

/* TYPED TEXT */

.typed-text {
  font-size: 2rem;
  color: var(--primary-white);
}

@media (max-width: 768px) {
  .main-info h1 {
    font-size: 1.7rem !important;
  }
  .typed-text {
    font-size: 1.3rem !important;
  }
}

/* HEADER BUTTON */

.btn-main-offer {
  border: none;
  text-transform: uppercase;
  border-radius: 0;
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  color: var(--primary-white);
  /* margin: 2rem; */
  /* z-index: 4 !important; */
  background: var(--primary-dark-grey);
}

.btn-main-offer:hover {
  text-decoration: none;
  background-color: var(--primary-blue);
  color: var(--primary-white);
  transition: 0.3s ease-in-out;
  /* z-index: 4; */
}

/* PARTICLES JS */

.tsparticles-canvas-el {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 2;
}

@media (max-width: 768px) {
  .particles-canvas {
    display: none;
  }
}

/* ABOUT ME COMPONENT */

.photo-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-img {
  width: 16rem;
  border-radius: 50%;
  padding: 0.3rem;
  border: 1px solid var(--primary-orange);
  box-shadow: 3px 3px 10px 0px rgba(132, 132, 132, 0.5);
}

.about-heading {
  color: var(--primary-blue);
  text-transform: uppercase;
}

/* CARDS */

.cards-row {
  display: flex;
  justify-content: center;
  align-content: center;
}

.card {
  margin: 0.5rem 1rem;
  min-width: 16.875rem;
  border-radius: 3px;
  border: 2px;
  border-color: var(--primary-orange);
  box-shadow: 3px 3px 10px 0px rgba(132, 132, 132, 0.5);

  background: var(--primary-light-grey);
  /* box-shadow: 3px 3px 10px 0px rgba(132,132,132,0.5); */
}

.card-title {
  color: var(--primary-blue);
  font-weight: 900;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 200;
}
.card-text {
  text-align: center;
  font-size: large;
}

@media (max-width: 468px) {
  .profile-img {
    width: 10rem;
  }
}

/* SERVICES COMPONENT */

.services {
  text-align: center;
  padding-bottom: 2rem;
  background: var(--primary-light-grey);
}

.services h1 {
  color: var(--primary-blue);
  text-transform: uppercase;
}

.services .circle {
  position: relative;
  margin: 0.375rem auto;
  background: var(--primary-orange);
  border-radius: 50%;
  width: 3.125rem;
  height: 3.125rem;
}

.services .icon {
  font-size: 1.4rem;
  color: var(--primary-white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.services .box {
  height: 16rem;
  border-bottom: 0.325rem solid var(--primary-orange);
  padding: 0.625rem;
  margin-bottom: 1.875rem;
  background: var(--primary-white);
  transition: 0.3s ease-in-out;
  box-shadow: 3px 3px 10px 0px rgba(132,132,132,0.5);
  border-radius: 3px;
}

.services .box:hover {
  background: var(--primary-orange);
  border-bottom: 0.325rem solid var(--primary-white);
}

.services .box:hover .icon {
  color: var(--primary-dark);
}

.services .box:hover .circle {
  background: var(--primary-white);
}

.services .box:hover h3 {
  color: var(--primary-white);
}

.services .box:hover p {
  color: var(--primary-white);
}

/* TIMELINE COMPONENT */

.experience {
  padding: 0.7rem;
}

.experience-wraper {
  position: relative;
  padding: 3.125rem;
  overflow: hidden;
}

.experience h1 {
  text-transform: uppercase;
  color: var(--primary-blue);
}

.experience-wraper:before {
  position: absolute;
  content: '';
  top: 0;
  left: 50%;
  margin-left: -0.0625rem;
  width: 0.125rem;
  height: 100%;
  background: var(--primary-grey);
  z-index: 1;
}

.timeline-block {
  width: calc(50% + 0.5rem);
  display: flex;
  justify-content: space-between;
  clear: both;
}

.timeline-block-right {
  float: right;
}

.timeline-block-left {
  float: left;
  direction: rtl;
}

.marker {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 0.3125rem solid var(--primary-dark);
  background: var(--primary-orange);
  margin-top: 0.625rem;
  z-index: 3;
}

.timeline-content {
  width: 95%;
  padding: 0 0.9375rem;
  color: var(--primary-dark-grey);
  direction: ltr;
}

.timeline-content h3 {
  margin-top: 0.3125rem;
  color: var(--primary-red);
  font-weight: 900;
}

.timeline-content b {
  color: var(--primary-blue);
  font-size: large;
}

/* .timeline-block-left h3 {
  text-align: right;
} */

@media (max-width: 768px) {
  .experience .container {
    padding-left: 0;
  }
  .experience-wraper:before {
    left: 0.5rem;
    margin-bottom: 1.875rem;
  }
  .timeline-block-right {
    float: none;
    width: 100%;
  }
  .timeline-block-left {
    float: none;
    direction: ltr;
    width: 100%;
  }
}

/* PORTFOLIO COMPONENT */

.portfolio-wraper {
  background: var(--primary-light-grey);
  padding: 3rem 0;
  margin: 0 auto !important;
}

.portfolio-wraper h1 {
  color: var(--primary-blue);
}

.image-box-wraper {
  width: 100vw;
  margin: 0 auto;
}

@media (min-width: 1000px) {
  .portfolio-image {
    width: 40vw !important;
  }
}

.portfolio-image {
  width: 100vw;
  border: 1px solid var(--primary-light-grey);
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 0 0 5px 5px;
  height: auto;
}

@media (min-width: 1200px) {
  .portfolio-image-box {
    width: 40vw !important;
  }
}

.portfolio-image-box {
  width: 100vw;
  background: var(--primary-white);
  border-radius: 5px;
  box-shadow: 3px 3px 8px 0px rgba(132, 132, 132, 0.2);
  position: relative;
  margin: 1rem;
  object-fit: cover !important;
  cursor: pointer;
}

.portfolio-image-box h3 {
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  font-family: 'Nunito', sans-serif;
  font-weight: 200;
  font-size: 1.5rem;
  text-align: center;
  color: var(--primary-dark-grey);
}

@media (max-width: 768px) {
  .portfolio-image {
    width: 100%;
    height: auto;
    cursor: pointer;
  }
}

.portfolio-image-box:hover .overflow {
  opacity: 60%;
  transition: 0.3s ease-in-out;
}

.portfolio-icon {
  position: absolute;
  opacity: 0;
  margin-left: -25.5rem;
  margin-top: 10rem;
  color: var(--primary-light-grey);
  font-size: 2rem;
}

.portfolio-image-box:hover .portfolio-icon {
  opacity: 70%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

/* POPUP BOX */

.portfolio-image-popupbox {
  width: 100%;
  object-fit: cover;
}

.popupbox-wrapper {
  border-radius: 3px;
  overflow: scroll !important;
  max-width: 80%;
  max-height: 75vh !important;
  min-width: 300px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.9);
  background-color: white;
}

.popupbox-content {
  overflow: auto !important;
}

@media (min-width: 1200px) {
  .popupbox-content {
    overflow: scroll !important;
    max-width: 50vw;
    padding: 20px 24px 30px;
  }

  .popupbox-content h3 {
    margin: 0.5rem 1rem;
    font-family: 'Nunito', sans-serif;
    font-weight: 300;
  }

  .popupbox-content p {
    margin: 0.5rem 1rem;
    font-family: 'Nunito', sans-serif;
    font-weight: 200;
    font-size: 1.1rem;
  }

  .popupbox-content button {
    margin: 0.5rem 0 0.5rem 1rem;
  }
}

.btn-projects {
  cursor: pointer;
  color: var(--primary-white);
  margin-right: 0.5rem;
  border-radius: 3px;
  width: 6rem;
  background: var(--primary-grey);
}

@media (max-width: 768px) {
  .portfolio-image-popupbox {
    width: 100%;
  }
}

@media (max-height: 570px) {
  .popupbox-wraper {
    height: 100% !important;
  }
  .portfolio-image-popupbox {
    width: 50%;
  }
}

@media (max-height: 280px) {
  .popupbox-wraper {
    height: 90% !important;
  }
  .portfolio-image-popupbox {
    width: 30%;
  }
}

/* CONTACT FORM COMPONENT */

.contact {
  background: var(--primary-white);
  padding: 3rem 0;
  height: 60vh;
}

.contact h1 {
  color: var(--primary-blue);
  padding: 1.3rem;
  text-transform: uppercase;
}

.contact p {
  color: var(--primary-dark-grey);
  font-size: 1.2rem;
}

.contact input,
.contact textarea {
  width: 100%;
  box-sizing: border-box;
  background: none;
  resize: none;
  border: 0;
  border-bottom: 0.125rem solid var(--primary-dark-grey);
  border-radius: 0;
  color: var(--primary-blue);
  outline: 0 !important;
  box-shadow: none !important;
  top: 0;
  /* margin-bottom: 1rem; */
  padding-left: 0 !important;
}

.contact .container {
  padding: 4rem 0;
}

.contact input:focus,
.contact textarea:focus {
  background: transparent;
  color: var(--primary-blue);
  border-bottom: 0.125rem solid var(--primary-dark-grey);
}

.contact textarea {
  height: 6rem !important;
}

.contact-btn {
  width: 100%;
}

.line {
  position: relative;
  width: 0;
  top: -1.07rem;
  height: 0.125rem;
  background: var(--primary-blue);
  display: inline-block;
}

input:focus + .line,
textarea:focus + .line {
  width: 100%;
  transition: width 0.5s ease-in-out;
}

input .form-control {
  margin: -0.3rem 0;
}

textarea .form-control {
  margin: -0.3rem 0;
}

.error-message {
  color: var(--primary-red);
}

.success-message {
  font-weight: 900;
  color: var(--primary-green);
}

@media (max-width: 768px) {
  .contact {
    overflow-x: hidden;
  }
  .contact .container {
    padding: 0 1rem;
  }
  .contact p {
    padding: 0 0.5rem;
  }
}

/* FOOTER COMPONENT */

.footer {
  background: var(--primary-light-grey);
  color: var(--primary-white);
  padding: 3rem;
  height: 4rem !important;
  position: relative;
}

.footer-icons {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.footer-copyright p {
  margin: 1.5rem 0;
  position: absolute;
  /* top: 50%; */
  left: 50%;
  transform: translate(-50%);
  color: var(--primary-dark-grey);
}

.footer-icons a {
  text-decoration: none;
  margin-bottom: 2rem;
  color: var(--primary-dark-grey);
  margin: 0 1rem;
}

.footer a:hover {
  text-decoration: none;
  color: var(--primary-orange);
  cursor: pointer;
}

