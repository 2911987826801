/* SERVICES COMPONENT */

.services {
  text-align: center;
  padding-bottom: 2rem;
  background: var(--primary-light-grey);
}

.services h1 {
  color: var(--primary-blue);
  text-transform: uppercase;
}

.services .circle {
  position: relative;
  margin: 0.375rem auto;
  background: var(--primary-orange);
  border-radius: 50%;
  width: 3.125rem;
  height: 3.125rem;
}

.services .icon {
  font-size: 1.4rem;
  color: var(--primary-white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.services .box {
  height: 16rem;
  border-bottom: 0.325rem solid var(--primary-orange);
  padding: 0.625rem;
  margin-bottom: 1.875rem;
  background: var(--primary-white);
  transition: 0.3s ease-in-out;
  box-shadow: 3px 3px 10px 0px rgba(132,132,132,0.5);
  border-radius: 3px;
}

.services .box:hover {
  background: var(--primary-orange);
  border-bottom: 0.325rem solid var(--primary-white);
}

.services .box:hover .icon {
  color: var(--primary-dark);
}

.services .box:hover .circle {
  background: var(--primary-white);
}

.services .box:hover h3 {
  color: var(--primary-white);
}

.services .box:hover p {
  color: var(--primary-white);
}
