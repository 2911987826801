/* CONTACT FORM COMPONENT */

.contact {
  background: var(--primary-white);
  padding: 3rem 0;
  height: 60vh;
}

.contact h1 {
  color: var(--primary-blue);
  padding: 1.3rem;
  text-transform: uppercase;
}

.contact p {
  color: var(--primary-dark-grey);
  font-size: 1.2rem;
}

.contact input,
.contact textarea {
  width: 100%;
  box-sizing: border-box;
  background: none;
  resize: none;
  border: 0;
  border-bottom: 0.125rem solid var(--primary-dark-grey);
  border-radius: 0;
  color: var(--primary-blue);
  outline: 0 !important;
  box-shadow: none !important;
  top: 0;
  /* margin-bottom: 1rem; */
  padding-left: 0 !important;
}

.contact .container {
  padding: 4rem 0;
}

.contact input:focus,
.contact textarea:focus {
  background: transparent;
  color: var(--primary-blue);
  border-bottom: 0.125rem solid var(--primary-dark-grey);
}

.contact textarea {
  height: 6rem !important;
}

.contact-btn {
  width: 100%;
}

.line {
  position: relative;
  width: 0;
  top: -1.07rem;
  height: 0.125rem;
  background: var(--primary-blue);
  display: inline-block;
}

input:focus + .line,
textarea:focus + .line {
  width: 100%;
  transition: width 0.5s ease-in-out;
}

input .form-control {
  margin: -0.3rem 0;
}

textarea .form-control {
  margin: -0.3rem 0;
}

.error-message {
  color: var(--primary-red);
}

.success-message {
  font-weight: 900;
  color: var(--primary-green);
}

@media (max-width: 768px) {
  .contact {
    overflow-x: hidden;
  }
  .contact .container {
    padding: 0 1rem;
  }
  .contact p {
    padding: 0 0.5rem;
  }
}
