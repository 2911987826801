/* NAVBAR COMPONENT */

/* .logo {
  width: auto;
  border-radius: 50%;
  height: 3.5rem;
} */

nav {
  z-index: 2;
}

.navbar {
  height: 4rem;
}

.navbar-brand {
  max-width: 17.25rem;
  padding: 0 !important;
  height: 4rem !important;
}

.navbar-brand h2 {
  font-family: 'Hachi Maru Pop', cursive;
  font-size: 2.5rem;
  color: var(--primary-blue);
}

@media (max-width: 768px) {
  .navbar-brand {
    width: 7rem;
  }
}

/* MENU LINKS  */

nav a.nav-link {
  /* font-weight: 500; */
  font-size: 0.8rem;
  letter-spacing: 0.0375rem;
  cursor: pointer;
}

nav ul li {
  text-transform: uppercase;
}

.navbar-nav > li > a {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  color: var(--primary-dark) !important;
  border-top: 0.1875rem solid var(--primary-dark);
}

.navbar-nav > li > a.active,
.navbar-nav > li > a:focus {
  border-top: 0.1875rem solid var(--primary-orange);
}

.navbar-nav > li > a:hover {
  border-top: 0.1875rem solid var(--primary-orange);
}

.navbar-toggler {
  color: var(--primary-dark);
  background: var(--primary-dark);
}
