/* FOOTER COMPONENT */

.footer {
  background: var(--primary-light-grey);
  color: var(--primary-white);
  padding: 3rem;
  height: 4rem !important;
  position: relative;
}

.footer-icons {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.footer-copyright p {
  margin: 1.5rem 0;
  position: absolute;
  /* top: 50%; */
  left: 50%;
  transform: translate(-50%);
  color: var(--primary-dark-grey);
}

.footer-icons a {
  text-decoration: none;
  margin-bottom: 2rem;
  color: var(--primary-dark-grey);
  margin: 0 1rem;
}

.footer a:hover {
  text-decoration: none;
  color: var(--primary-orange);
  cursor: pointer;
}
