/* ABOUT ME COMPONENT */

.photo-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-img {
  width: 16rem;
  border-radius: 50%;
  padding: 0.3rem;
  border: 1px solid var(--primary-orange);
  box-shadow: 3px 3px 10px 0px rgba(132, 132, 132, 0.5);
}

.about-heading {
  color: var(--primary-blue);
  text-transform: uppercase;
}

/* CARDS */

.cards-row {
  display: flex;
  justify-content: center;
  align-content: center;
}

.card {
  margin: 0.5rem 1rem;
  min-width: 16.875rem;
  border-radius: 3px;
  border: 2px;
  border-color: var(--primary-orange);
  box-shadow: 3px 3px 10px 0px rgba(132, 132, 132, 0.5);

  background: var(--primary-light-grey);
  /* box-shadow: 3px 3px 10px 0px rgba(132,132,132,0.5); */
}

.card-title {
  color: var(--primary-blue);
  font-weight: 900;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 200;
}
.card-text {
  text-align: center;
  font-size: large;
}

@media (max-width: 468px) {
  .profile-img {
    width: 10rem;
  }
}
